<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add feed</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Collection</label>
                <select
                  class="form-control"
                  name="collection_id"
                  ref="f_collection_id"
                >
                  <option
                    v-for="collection in collections"
                    :value="collection.id"
                    :key="collection.id"
                  >
                    {{ collection.name }}
                  </option>
                </select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="feed.feed_url"
                  required
                  label="Feed URL"
                  ref="f_feed_url"
                  :rules="[rules.required, rules.url]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="feed.name"
                  required
                  label="Feed name (internal)"
                  ref="f_name"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <select
                  class="form-control"
                  name="feed_type_id"
                  ref="f_feed_type_id"
                >
                  <option
                    v-for="feed_type in feed_types"
                    :value="feed_type.id"
                    :key="feed_type.id"
                    :selected="feed.feed_type_id == feed_type.id ? true : false"
                  >
                    {{ feed_type.name }}
                  </option>
                </select>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary dark large"
            ref="submit"
            @click="save"
            :loading="loading.submit"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  REFRESH,
  POST_XML_FEED,
  PULL_XML_FEED_TYPES,
  PULL_XML_FEEDS
} from "@/core/services/store/xmlfeed.module";
import { PULL_COLLECTIONS } from "@/core/services/store/collections.module";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    loading: {
      submit: false
    },
    term: "",
    rules: {
      required: value => !!value || "Required.",
      counter: value => value.length <= 20 || "Max 20 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return pattern.test(value) || "Invalid website url.";
      }
    }
  }),
  methods: {
    refresh: function() {
      this.$store.dispatch(PULL_COLLECTIONS);
      this.term = "";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading.submit = true;
        this.$store
          .dispatch(POST_XML_FEED, {
            feed_url: this.$refs.f_feed_url.value,
            name: this.$refs.f_name.value,
            feed_type_id: this.$refs.f_feed_type_id.value,
            collection_id: this.$refs.f_collection_id.value
          })
          .then(() => {
            this.dialog = false;
            this.loading.submit = false;
            this.$store.dispatch(PULL_XML_FEEDS);
          });
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.collections.errors,
      message: state => state.collections.message,
      hasMessage: state => state.collections.hasMessage,
      feed: state => state.xmlfeed.model,
      feed_types: state => state.xmlfeed.feed_types,
      collections: state => state.collections.collections,
      collectionSelected: state => state.collections.collectionSelected
    }),
    ...mapGetters(["selectedCollection"])
  },
  beforeCreate() {
    this.$store.dispatch(PULL_XML_FEED_TYPES);
    this.$store.dispatch(REFRESH);
  }
};
</script>
