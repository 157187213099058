<template>
  <section>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                Import data
                <span class="d-block text-muted pt-2 font-size-sm"
                  >Add your xml feed and be close to build effective search
                  engine.</span
                >
              </h3>
            </div>
            <div class="card-toolbar">
              <v-flex>
                <import_add />
              </v-flex>
            </div>
          </div>
          <div class="card-body">
            <div
              :class="`alert alert-${message.color}`"
              role="alert"
              v-if="message.show"
            >
              {{ message.text }}
            </div>
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    :headers="headers"
                    :items="feeds"
                    :loading="loading"
                    :options.sync="options"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.progress="{ item }">
                      <v-progress-linear
                        :value="item.progress"
                      ></v-progress-linear>
                      ({{ item.progress }}%)
                    </template>
                    <template v-slot:item.filesize="{ item }">
                      {{ Math.ceil(item.filesize / 1024 / 1024) }} MB
                    </template>
                    <template v-slot:item.took_time="{ item }">
                      {{ item.took_time }}s
                    </template>
                    <template v-slot:item.error_count="{ item }">
                      <span v-if="item.error_count > 0">
                        <router-link
                          v-slot="{ href }"
                          :to="{
                            name: 'import-error-data',
                            query: { id: item.id }
                          }"
                        >
                          <a :href="href">
                            {{ item.error_count }}
                          </a>
                        </router-link>
                      </span>
                      <span v-else>
                        0
                      </span>
                    </template>
                    <template v-slot:item.feed_type_id="{ item }">
                      <span v-if="item.feed_type_id === '1'">Main</span>
                      <span v-else>Secondary</span>
                    </template>
                    <template v-slot:top>
                      <div class="row">
                        <div
                          class="d-flex align-items-center position-relative my-1"
                        >
                          <span
                            class="svg-icon svg-icon-1 position-absolute ms-6"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height="2"
                                rx="1"
                                transform="rotate(45 17.0365 15.1223)"
                                fill="black"
                              ></rect>
                              <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                          <input
                            type="text"
                            v-model="search"
                            placeholder="Search query"
                            label="Search"
                            class="form-control form-control-solid w-250px ps-14"
                          />
                        </div>
                        <div
                          class="d-flex align-items-center position-relative my-1 ml-2"
                        >
                          <v-chip-group
                            multiple
                            v-model="activeFilters.collection_name"
                            class="py-2"
                          >
                            <template v-for="item in filters.collection_name">
                              <v-chip
                                :key="`${item}`"
                                :value="item"
                                :filter="
                                  activeFilters.collection_name.includes(item)
                                "
                                :ripple="false"
                              >
                                {{ item }}
                              </v-chip>
                            </template>
                          </v-chip-group>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <b-dropdown text="Actions">
                        <b-dropdown-item @click.prevent="editItem(item)">
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="reloadItem(item)">
                          Reload
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  PULL_XML_FEEDS,
  RELOAD_FEED
} from "@/core/services/store/xmlfeed.module";
import { mapGetters, mapState } from "vuex";
import import_add from "@/view/pages/import/import_add";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import Swal from "sweetalert2";

export default {
  components: { import_add },

  data() {
    return {
      options: {},
      search: "",
      timer: "",
      filters: { collection_name: [] },
      activeFilters: [{ collection_name: [] }],
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Collection Name",
          value: "collection_name",
          filter: value => {
            return this.activeFilters.collection_name
              ? this.activeFilters.collection_name.includes(value)
              : true;
          }
        },
        { text: "URL", value: "feed_url" },
        { text: "Type", value: "feed_type_id" },
        { text: "Last imported", value: "last_import" },
        { text: "Total Items in Elastic", value: "total_count" },
        { text: "Items with error", value: "error_count" },
        { text: "Waiting for push", value: "waiting_count" },
        { text: "Archived", value: "archive_count" },
        { text: "Progress", value: "progress" },
        { text: "Last process (s)", value: "took_time" },
        { text: "File Size (MB)", value: "filesize" },
        { text: "Message", value: "message", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    initFilters() {
      for (let col in this.filters) {
        this.filters[col] = this.feeds
          .map(d => {
            return d[col];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }

      this.activeFilters = Object.assign({}, this.filters);
    },
    editItem(item) {
      this.$router.push({ name: "import-edit", query: { id: item.id } });
    },
    reloadItem(item) {
      this.$store.dispatch(RELOAD_FEED, item.id).then(() => {
        Swal.fire("The Feed will reloaded in moment.", "", "success");
      });
    },
    reloadData() {
      this.$store.dispatch(PULL_XML_FEEDS);
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    },
    feeds() {
      this.initFilters();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.xmlfeed.errors,
      message: state => state.message.message,
      feeds: state => state.xmlfeed.feeds,
      loading: state => state.xmlfeed.loading
    }),
    ...mapGetters(["currentProject"])
  },
  mounted() {
    this.$store.dispatch(RELOAD_MESSAGE);
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Import" }]);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
